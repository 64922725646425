<template>
  <div class="reservations" :class="{skeleton: !state.reservation.loaded}">
    <div class="top">
      <span class="font-sm">총 {{ state.reservation.total }}개</span>
      <div class="right d-flex">
        <select class="form-control font-xs" v-model="state.condition.projectType" @change="setCondition('projectType', state.condition.projectType)">
          <option value="reward">후원형</option>
          <option value="invest">증권형</option>
        </select>
        <select class="form-control font-xs" v-model="state.condition.opened" @change="setCondition('opened', state.condition.opened)">
          <option value="false">오픈예정 프로젝트</option>
          <option value="true">오픈된 프로젝트</option>
        </select>
        <label :for="`${component.name}Search`" class="search">
          <Number :id="`${component.name}Search`" placeholder="프로젝트 번호로 찾기" :enter="search" :noComma="true" :value.sync="state.condition.projectSeq"/>
          <div class="actions">
            <span class="reset" @click="reset()" v-if="$route.query.projectSeq">&times;</span>
            <button class="btn btn-default" @click="search()">
              <i class="fa fa-search"></i>
            </button>
          </div>
        </label>
      </div>
    </div>
    <ul class="view-conditions tight">
      <li class="title">아래 조건 중 하나를 충족할 시 리스트에 노출</li>
      <li>
        <span class="pr-1 font-weight-500">필수</span>
        <span>간단 소개 작성, 공개 범위가 '전체 공개' 혹은 '간단 소개만 공개'로 체크</span>
      </li>
      <li>
        <span class="pr-1 font-weight-500">조건1</span>
        <span>오픈 예정일이 현시각 보다 이후</span>
      </li>
      <li>
        <span class="pr-1 font-weight-500">조건2</span>
        <span>레거시 어드민 - 프로젝트 상세 - 공개여부가 체크되어 있고 프로젝트 상태가 '진행 중'이 아니며 프로젝트 마감일이 현시각보다 이후</span>
      </li>
    </ul>
    <div class="list row" v-if="!state.reservation.loaded || state.reservation.list.length">
      <div class="item col-lg-3" v-for="(r, idx) in state.reservation.list" :key="idx" :class="{skeleton: r.skeleton}" :id="`${component.name}Item`">
        <div class="inner">
          <div class="background">
            <span class="img" :style="{backgroundImage: `url('${computedThumbnailUrl(r.thumbFileUrl)}')`}"></span>
          </div>
          <div class="below">
            <div class="above">
              <div class="title d-flex justify-content-between">
                <span class="project-name ellipsis">{{ r.projectName || "(N/A)" }}</span>
                <button class="btn btn-default flex-shrink-0" @click="openApplicants(r.reservationSeq)" v-if="r.reservationSeq">신청자 목록</button>
              </div>
              <div class="info">
                <div>
                  <span class="name">프로젝트 유형</span>
                  <span>{{ r.projectType === "reward" ? "후원형" : "증권형" }}</span>
                </div>
                <div>
                  <span class="name">프로젝트 번호</span>
                  <span>{{ r.projectSeq }}</span>
                </div>
                <div>
                  <span class="name">진행자</span>
                  <span>{{ r.memberName }}</span>
                </div>
                <div>
                  <span class="name">오픈 예정일</span>
                  <span>{{ r.investStartDate || "(N/A)" }}</span>
                </div>
                <div>
                  <span class="name">공개 상태</span>
                  <span>{{ getOpenStatus(r.openStatus) }}</span>
                </div>
              </div>
            </div>
            <div class="actions d-flex">
              <button class="btn font-xs btn-secondary border" @click="preview(r)" v-if="r.reservationSeq">
                <span>{{ !isOpened(r.investStartDate) ? "미리보기" : "프로젝트 가기" }}</span>
              </button>
              <button class="btn font-xs" :class="[r.reservationSeq ? 'btn-secondary border' : 'btn-purple']" @click="openDetail({projectSeq: r.projectSeq, investStartDate: r.investStartDate})">
                <span v-if="!isOpened(r.investStartDate)">{{ !r.reservationSeq ? "등록" : "수정" }}</span>
                <span v-else>상세보기</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <NoData v-else/>
  </div>
</template>

<script>import {computed, defineComponent, onMounted, reactive, watch} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import Anchor from "@/components/Anchor";
import store from "@/scripts/store";
import http from "@/scripts/http";
import router from "@/scripts/router";
import lib from "@/scripts/lib";
import NoData from "@/components/NoData";
import Number from "@/components/Number";

function Component(initialize) {
  this.name = "pagesAdminProjectReservations";
  this.initialize = initialize;
}

export default defineComponent({
  components: {Number, NoData, Anchor},

  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      load(true);
    });

    const state = reactive({
      reservation: {
        loaded: false,
        more: false,
        list: [],
        total: 0,
        pageable: {
          page: 0,
          size: 0,
        },
      },
      condition: {
        projectSeq: null,
        opened: "false",
        projectType: "reward",
      },
    });

    const computedThumbnailUrl = computed(() => {
      return (thumbFilePath) => {
        return store.getters.thumbnailUrl(thumbFilePath);
      };
    });

    const load = (init, more) => {
      if (init) {
        const query = {};

        for (let i in state.condition) {
          if (state.condition[i] && !router.app.$route.query[i]) {
            query[i] = state.condition[i];
          } else if (router.app.$route.query[i]) {
            query[i] = router.app.$route.query[i];
            state.condition[i] = router.app.$route.query[i];
          }
        }

        if (JSON.stringify(query) !== JSON.stringify(router.app.$route.query)) {
          router.replace({query});
        }
      }

      const args = {
        page: 0,
        size: 50,
      };

      if (more) {
        state.reservation.more = true;
        args.page = state.reservation.pageable.page + 1;
      } else {
        state.reservation.loaded = false;
      }

      for (let i = 0; i < 5; i += 1) {
        state.reservation.list.push({
          skeleton: true,
          projectSeq: "000000",
          projectType: "Wait",
          content: "Please wait a moment",
          projectName: "Please wait a moment",
          investStartDate: "0000-00-00",
          fileOrigName: "Wait a moment",
          memberName: "Wait"
        });
      }

      for (let i in state.condition) {
        state.condition[i] && (args[i] = state.condition[i]);
      }

      http.get(`/api/admin/project/reservations`, args).then(({data}) => {
        if (more) {
          state.reservation.more = false;
          state.reservation.list = state.reservation.list.filter(r => !r.skeleton).concat(data.body.content);
        } else {
          state.reservation.loaded = true;
          state.reservation.list = data.body.content;
        }

        state.reservation.pageable = data.body.pageable;
        state.reservation.total = data.body.total;
      });
    };

    const openDetail = ({projectSeq, investStartDate}) => {
      store.commit("openModal", {
        name: "Reservation",
        params: {
          projectType: router.app.$route.query.projectType,
          projectSeq,
          isOpened: isOpened(investStartDate)
        },
        callback: `${component.name}.load`
      });
    };

    const openApplicants = (reservationSeq) => {
      store.commit("openModal", {
        name: "TableList",
        params: {
          url: `/api/admin/project/reservation/${reservationSeq}/applicants`,
          title: "오픈 알림 신청자 목록",
          head: [
            {
              title: "회원번호",
              name: "memberSeq"
            }, {
              title: "아이디",
              name: "email"
            }, {
              title: "이름",
              name: "memberName"
            }, {
              title: "신청일",
              name: "createDate"
            }, {
              title: "연락처",
              name: "mobile",
              type: "mobile"
            },
          ],
        }
      });
    };

    const setCondition = (key, value) => {
      if (value === router.app.$route.query[key] || (!router.app.$route.query[key] && !value)) {
        return;
      }

      const query = lib.getRenewed(router.app.$route.query);

      if (!value) {
        delete query[key];
      } else {
        query[key] = value;
      }

      router.push({query});
    };

    const search = () => {
      setCondition("projectSeq", state.condition.projectSeq);
    };

    const reset = () => {
      state.condition.projectSeq = null;
      setCondition("projectSeq", state.condition.projectSeq);
    };

    const preview = ({reservationSeq, investStartDate, projectType, projectSeq}) => {
      if (isOpened(investStartDate)) {
        return window.open(`/${projectType}/${projectSeq}`, "_blank", "noopener,noreferrer");
      }
      window.open(`/reservation/${reservationSeq}?preview=true`, "_blank", "noopener,noreferrer");
    };

    const getOpenStatus = (status) => {
      switch (status) {
        case "BEFORE_OPEN":
          return "공개하지 않음";
        case "ONLY_SIMPLE_TEXT":
          return "간단 소개만 공개";
        case "OPEN_ALL":
          return "전체 공개";
        default:
          return "(N/A)";
      }
    };

    const isOpened = (investStartDate) => {
      return new window.Date(investStartDate) < new window.Date();
    };

    const onScroll = () => {
      if (!state.reservation.loaded || state.reservation.more || (state.reservation.pageable.page >= Math.ceil(state.reservation.total / state.reservation.pageable.size) - 1)) {
        return;
      }

      const itemHeight = document.getElementById(`${component.name}Item`)?.offsetHeight;

      itemHeight * 5 >= document.body.offsetHeight - (window.innerHeight + window.scrollY)
      && load(false, true);
    };

    onMounted(() => {
      store.commit("addListener", [component.name, "onScroll", onScroll]);
    });

    watch(() => [router.app.$route.query.projectSeq, router.app.$route.query.opened, router.app.$route.query.projectType], (next, prev) => {
      if (JSON.stringify(next) !== JSON.stringify(prev)) {
        load();
      }
    });

    return {component, state, computedThumbnailUrl, load, openDetail, openApplicants, setCondition, search, reset, preview, getOpenStatus, isOpened};
  }
});
</script>

<style lang="scss" scoped>
.reservations {
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: $px10;

    .right {
      gap: $px10;

      .form-control, .btn, label {
        height: $px43;
        font-size: $px12;
      }

      select {
        border: $px1 solid $colorBorder;
        width: auto;
      }

      .search {
        border-radius: $px4;
        border: $px1 solid $colorBorder;
        display: flex;
        flex-shrink: 0;
        margin-bottom: 0;
        overflow: hidden;
        position: relative;
        transition: border-color 0.18s;

        .form-control {
          font-size: $px12 !important;
          width: auto;
          border: none;
        }

        .btn {
          border: none;
        }

        .actions {
          .reset {
            vertical-align: text-bottom;
            padding: $px10;
            margin-right: $px10;
            cursor: pointer;
            font-size: $px12;
          }

          .btn {
            border-radius: 0;
            background: #f7f7f7;
            padding: 0 $px15;

            &:focus {
              color: inherit;
            }
          }
        }

        &:focus-within {
          border: $px1 solid $colorPurple;
        }
      }
    }
  }

  .view-conditions {
    border-radius: $px4;
    background: $colorBackground;
    padding: $px15;
    font-size: $px12;
    margin-top: $px10;

    > .title {
      font-weight: 600;
    }
  }

  .list {
    font-size: $px14;
    padding-bottom: $px30;

    .item {
      margin-top: $px30;

      .background {
        font-size: 0;
        background: $colorBackground;

        .img {
          background-size: auto 100%;
          background-position: center;
          background-repeat: no-repeat;
          display: inline-block;
          width: 100%;
          padding-top: $ratio53Percent;
        }
      }

      .below {
        margin-top: $px8;
        overflow: hidden;

        .above {
          height: $px130;

          .title {
            color: $colorDark;
            font-size: $px16;
            font-weight: 500;

            .btn {
              font-size: $px10;
              padding: $px4 $px8;
            }
          }

          .info {
            color: #777;
            display: flex;
            flex-direction: column;
            font-size: $px12;
            gap: $px3;
            margin-top: $px4;

            > div {
              display: flex;

              .name {
                display: inline-block;
                flex-shrink: 0;
                width: $px80;
              }

              span, .btn {
                display: inline-block;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }
          }
        }

        .actions {
          margin-top: $px8;

          .btn {
            padding: $px4 $px8;
            height: $px34;
            width: 100%;

            &.btn-secondary:hover {
              background: $colorSecondary;
              color: #fff;
            }
          }
        }
      }
    }
  }

  .no-data {
    padding: $px100 0;
  }

  &.skeleton {
    .top {
      span, .form-control, .btn, label {
        @include skeleton;
      }

      .right .search {
        border: none;
      }
    }

    .list {
      .item {
        .background, .background span.img {
          @include skeleton;
        }

        .below {
          .above {
            .title, .title .btn, .info div {
              @include skeleton;
            }
          }

          .actions .btn {
            @include skeleton;
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .top {
      flex-direction: column;
      align-items: start;

      .right {
        flex-wrap: wrap;
      }
    }
  }
}
</style>